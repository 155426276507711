import { defineStore } from 'pinia'
import { ApiResponse } from 'ic-js-util'
import { getNavigationById } from 'ic-js-navigation'
import { NavigationData } from '~/assets/types/navigation'

export const useNavigationStore = defineStore('navigation', () => {
	const navigationData = ref<NavigationData | undefined>(undefined)
	const clubNavigationData = ref<NavigationData | undefined>(undefined)
	const secondNavigationData = ref<NavigationData | undefined>(undefined)

	const config = useRuntimeConfig()
	const fetchNavigation = async () => {
		const navigationResponse = await getNavigationById(config.public.navigationIds.mainNav) as any as ApiResponse<NavigationData>
		const clubNavigationResponse = await getNavigationById(config.public.navigationIds.clubsNav) as any as ApiResponse<NavigationData>
		const secondNavigationResponse = await getNavigationById(config.public.navigationIds.secondaryNav) as any as ApiResponse<NavigationData>
		navigationData.value = navigationResponse.data
		clubNavigationData.value = clubNavigationResponse.data
		secondNavigationData.value = secondNavigationResponse.data
	}

	return { navigationData, secondNavigationData, clubNavigationData, fetchNavigation }
})
